import { request } from "./network";

// 获取准考证信息无需登录
export function getZkzInfoBySfz(params) {
  return request({
    method: "get",
    url: "/web/zkz/getZkzInfoBySfz",
    params
  });
}
