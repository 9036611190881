export function add0(m) {
  return m < 10 ? "0" + m : m;
}
export function formatDate(data) {
  var time = new Date(data);
  var y = time.getFullYear();
  var m = time.getMonth() + 1;
  var d = time.getDate();
  return y + "-" + add0(m) + "-" + add0(d);
}
export function formatTime(data) {
  var time = new Date(data);
  var h = time.getHours();
  var mm = time.getMinutes();
  return add0(h) + ":" + add0(mm);
}
