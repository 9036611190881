<template>
  <div class="conetnt2">
    <div class="header_box">
      <div></div>
    </div>
    <div class="c_txt">清华大学 2022 年本科招生考试考生安全承诺登记表</div>
    <table cellspacing="0px">
      <tr>
        <td width="15%">测试类型</td>
        <td width="85%" colspan="3">
          <div class="checkbox">
            <div class="checkbox-item">
              <div class="checkbox-box"></div>
              <div>艺术类</div>
            </div>
            <div class="checkbox-item">
              <div class="checkbox-box"></div>
              <div>体育类</div>
            </div>
          </div>
        </td>
      </tr>
      <tr>
        <td width="15%">姓<span style="padding: 0 1em;"></span>名</td>
        <td width="30%"></td>
        <td width="15%">准考证号</td>
        <td width="40%"></td>
      </tr>
      <tr>
        <td width="15%"><span>省<span style="padding: 0 1em;"></span>份</span></td>
        <td width="30%"></td>
        <td width="15%"><span>中<span style="padding: 0 1em;"></span>学</span></td>
        <td width="40%"></td>
      </tr>
      <tr>
        <td width="15%">联系方式</td>
        <td width="30%"></td>
        <td width="15%">常住地址</td>
        <td width="40%"></td>
      </tr>
      <tr>
        <td width="45%" colspan="2">
          <div style="font-family: '中华软宋'; font-weight: bold">本人14天内到达或途径的城市</div>
          <div>（须与动态行程卡一致）</div>
        </td>
        <td width="55%" colspan="2"></td>
      </tr>
      <tr>
        <td width="45%" colspan="2">
          <div style="font-family: '中华软宋'; font-weight: bold">本人行程卡所涉城市是否带星号*</div>
          <div>（如有星号*，写明具体城市））</div>
        </td>
        <td width="55%" colspan="2"></td>
      </tr>
      <tr>
        <td colspan="4" style="font-family: '中华软宋'; font-weight: bold">
          14天体温记录
        </td>
      </tr>
      <tr>
        <td colspan="4">
          <table cellspacing="0px">
            <thead>
            <tr>
              <td width="100">天数</td>
              <td width="300">日期</td>
              <td width="100">体温</td>
              <td width="100">天数</td>
              <td width="300">日期</td>
              <td width="100">体温</td>
            </tr>
            </thead>
            <tbody>
            <tr>
              <td>第1天</td>
              <td>
                <span>年</span>
                <span>月</span>
                <span>日</span>
              </td>
              <td><span>℃</span></td>
              <td>第8天</td>
              <td>
                <span>年</span>
                <span>月</span>
                <span>日</span>
              </td>
              <td><span>℃</span></td>
            </tr>
            <tr>
              <td>第2天</td>
              <td>
                <span>年</span>
                <span>月</span>
                <span>日</span>
              </td>
              <td><span>℃</span></td>
              <td>第9天</td>
              <td>
                <span>年</span>
                <span>月</span>
                <span>日</span>
              </td>
              <td><span>℃</span></td>
            </tr>
            <tr>
              <td>第3天</td>
              <td>
                <span>年</span>
                <span>月</span>
                <span>日</span>
              </td>
              <td><span>℃</span></td>
              <td>第10天</td>
              <td>
                <span>年</span>
                <span>月</span>
                <span>日</span>
              </td>
              <td><span>℃</span></td>
            </tr>
            <tr>
              <td>第4天</td>
              <td>
                <span>年</span>
                <span>月</span>
                <span>日</span>
              </td>
              <td><span>℃</span></td>
              <td>第11天</td>
              <td>
                <span>年</span>
                <span>月</span>
                <span>日</span>
              </td>
              <td><span>℃</span></td>
            </tr>
            <tr>
              <td>第5天</td>
              <td>
                <span>年</span>
                <span>月</span>
                <span>日</span>
              </td>
              <td><span>℃</span></td>
              <td>第12天</td>
              <td>
                <span>年</span>
                <span>月</span>
                <span>日</span>
              </td>
              <td><span>℃</span></td>
            </tr>
            <tr>
              <td>第6天</td>
              <td>
                <span>年</span>
                <span>月</span>
                <span>日</span>
              </td>
              <td><span>℃</span></td>
              <td>第13天</td>
              <td>
                <span>年</span>
                <span>月</span>
                <span>日</span>
              </td>
              <td><span>℃</span></td>
            </tr>
            <tr>
              <td>第7天</td>
              <td>
                <span>年</span>
                <span>月</span>
                <span>日</span>
              </td>
              <td><span>℃</span></td>
              <td>第14天</td>
              <td>
                <span>年</span>
                <span>月</span>
                <span>日</span>
              </td>
              <td><span>℃</span></td>
            </tr>
            </tbody>
          </table>
        </td>
      </tr>
      <tr>
        <td colspan="4" style="font-family: '中华软宋'; font-weight: bold">
          本人健康承诺
        </td>
      </tr>
      <tr>
        <td style="text-align: left" colspan="4" rowspan="6">
          <div>
            1、本人及共同居住家庭成员是否有发热、呼吸道症状等不适症状：
            是／否
          </div>
          <div>
            2、本人及共同居住家庭成员是否有新冠肺炎确诊史或疑似就诊史：
            是／否
          </div>
          <div>
            3、本人及共同居住家庭成员近14天是否有新冠肺炎确诊、疑似病例或无症状感染者接触史，是否接触过有病例报告社区人员：
            是／否
          </div>
          <div>
            4、本人及共同居住家庭成员近21天内是否有境内中高风险地区居住、旅行史：
            是／否
          </div>
          <div>
            5、本人及共同居住家庭成员近21天内是否有境外居住、旅行史：
            是／否
          </div>
        </td>
      </tr>
      <tfoot>
      <tr>
        <td colspan="4" style="text-align: left; padding: 10px">
          上述行程卡信息、体温记录和健康承诺真实有效，本人将密切关注并严格遵守所在省（区、市）及北京市的各项疫情防控要求，自觉配合疫情防控检查，充分理解并遵守考点各项防疫安全要求。遇有发热、乏力、咳嗽、呼吸困难、腹泻等病状出现，本人将及时报告，并立即就医。如因隐瞒病情、活动轨迹或接触史而引起影响公共安全的后果，本人愿承担相应的法律责任。
          <div>考生签字：</div>
          <div>日<span style="width: 2em;"></span>期：</div>
        </td>
      </tr>
      </tfoot>
    </table>
  </div>
</template>

<script>
import { getZkzInfoBySfz } from "r/index/printAtc.js";
import { formatTime, formatDate } from "s/time.js";
export default {
  name: "letter",
  data() {
    return {
      zkz_num: "",
      sfz_num: "",
      tableData: {},
    };
  },
  created() {

  },
  methods: {

  },
};
</script>


<style scoped lang="scss">
  .conetnt2 {
    width: 694px;
    height: 1082px;
    margin: 0 auto;
    padding: 20px 50px;
    background-color: #ffffff;

    .header_box {
      position: relative;

      .h_logo {
        float: left;

        img {
          width: 160px;
        }
      }

      .erq {
        float: right;
        position: absolute;
        right: 0;
        top: 50%;
        transform: translate(0, -50%);
      }
    }

    .c_txt {
      text-align: center;
      padding: 22px 0;
      font-size: 26px;
      font-weight: bold;
      font-family: "华文中宋";
    }

    .fy_text {
      position: relative;
      left: 10px;
      top: 10px;

      .fy_text_sign {
        font-weight: bold;
        font-size: 14px;
      }

      .fy_text_section {
        font-size: 12px;
        width: 640px;
      }
    }

    table {
      width: 100%;
      border: 2px solid #000000;

      tr {
        width: 100%;

        table {
          border: 0;
          padding: 0px;

          thead {
            tr {
              td {
                font-family: "仿宋";
                color: #333333;
                font-size: 14px;
                font-weight: bold;
                text-align: center;
                border-right: 1px solid #666666;
                border-bottom: 1px solid #666666;
                height: 28px;
              }
            }
          }

          tbody {
            tr {
              td {
                font-family: "仿宋";
                color: #333333;
                font-size: 14px;
                text-align: center;
                border-top: 1px solid #666666;
                height: 28px;

                span {
                  padding-left: 20px;
                }
              }
            }
          }
        }

        td {
          font-family: "仿宋";
          color: #333333;
          font-size: 14px;
          text-align: center;
          border-right: 1px solid #666666;
          border-bottom: 1px solid #666666;
          height: 40px;

          > span {
            padding: 0 1em;
          }

          div {
            line-height: 25px;
            padding-left: 10px;
          }

          .checkbox {
            padding: 0;
            display: flex;
            flex-flow: row;
            justify-content: center;
            align-items: center;
            .checkbox-item{
              display: flex;
              flex-flow: row;
              justify-content: center;
              align-items: center;
              padding: 0 80px 0 0;
              &:last-child{
                padding: 0;
              }
              .checkbox-box {
                width: 12px;
                height: 12px;
                line-height: 12px;
                padding: 0;
                border: 1px solid #000000 !important;
              }
              > div {
                padding-left: 5px;
              }
            }

          }

          &:last-child {
            border-right: 0;
          }

          img {
            // width: 100%;
            height: 200px;
            border: 0;
          }
        }

        &:last-child {
          td {
            border-bottom: 0;
          }
        }
      }

      tfoot {
        tr {
          td {
            text-indent: 26px;
            line-height: 25px;

            div {
              font-weight: bold;
              font-size: 20px;
              width: 100%;
              padding-left: 400px;
              margin-top: 10px;
              padding-bottom: 10px;
              font-family: "仿宋";
            }
          }
        }
      }
    }
  }
</style>
